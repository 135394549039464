/* formStyle.css */
.pk-form-style {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.pk-form-style h2 {
  text-align: center;
  margin-bottom: 20px;
}

.pk-form-style .form-label {
  font-weight: bold;
}

.pk-form-style .form-control {
  border-radius: 5px;
}

.pk-form-style .btn-primary {
  border-radius: 5px;
}

.pk-form{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-danger {
  text-align: center;
}
