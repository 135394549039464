
.serv-card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 15px 18px 20px;
  cursor: pointer;
  font-family: Noto Serif Devanagari;
}

.serv-card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  border: 1px solid #3E7DC0;
}

/* .toast-header {
    
justify-content: space-between;
} */

/* .toast {
  position: fixed;
  z-index: 999;
  top: 18%;
  left: 2%;
  width: 96%;
  height: 80%;
  background-color: #0d376ef8;
  color: #fff !important;
} */

.toast {
  position: fixed;
  z-index: 999;
  top: 18%;
  left: 2%;
  width: 96%;
  height: 80%;
  background-color: #0d376ef8;
  color: #fff !important;
  display: flex;
  flex-direction: column;
}

/* .toast-body-scroll {
  overflow-y: auto;
  flex-grow: 1;
  padding: 1rem;
} */

.toast-header {
  justify-content: space-between;
  background-color: #0d376ef8;
  color: #fff;
  border-bottom: 1px solid #fff;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1040; /* Ensure it is below the toast */
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1050; /* Ensure it is above the overlay */
  max-height: 70vh;
  overflow-y: auto;
}

.toast-body {
  max-height: 90vh;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .toast {
    bottom: 10px;
    right: 10px;
  }

  .toast-body {
    max-height: 90vh;
    overflow-y: auto;
  }
}


button.btn-close{
  color: #fff !important;
  background-color: rgb(255, 255, 255);


}



/* Disable scrolling */
.no-scroll {
  overflow: hidden;
}