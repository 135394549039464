.page-content {
  margin-top: 70px;
}



.page-header {
  padding: 70px 5% 60px 5%;
    background: #0c356a9f ;
  }
  

  .page-header h2 {
    font-size: 2.5rem;
  }