.carousel {
    width: 100%;
    height: 100%;
    margin-top: 85px;
}

    .carousel .carousel-inner, .carousel .carousel-item {
        width: 100%;
        height: 100%;
    }

    .carousel-item img {
        object-fit: cover;
        object-position: 50% 40%;
    }



.carousel-caption h5{
    background-color: #0c356abe ;
    padding: 10px 10px 10px 10px;
    color: #fff !important;
    border-radius: 5px;
}

    .News_tiker {
        background-color: #0C356A ;
        line-height: 3rem;
        color: #fff;
    }
