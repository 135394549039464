.Navbar {
    background-color: #0C356A !important;
}

.Logo {
    width: 70px;
    margin-right: 20px;
}

.pk-nav-btn{
    margin-left: 0px;
}


.pk-nav-btn.nav-link.btn {
    background-color:#ffffff;
    color: #0C356A;
font-size: 16px;
margin-left: 30px;
font-weight: bold;
}

.pk-nav-top .nav-link{
    font-size: 16px;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    padding:3px 10px 3px 10px !important;
}
.pk-nav-top .nav-link:focus{
  font-weight: bold;
  background-color: #0C356A;
  color: #fff;
}

.pk-nav-top .nav-link:hover{
    background-color: #fff;
    color: #0C356A;
    border-radius: 1rem;
  }
  

@media screen and (max-width: 480px) {

   

    .pk-nav-top .nav-link {
        text-align: center;
        font-size: 20px;
    }
    .pk-nav-btn.nav-link.btn {
        background-color:#ffffff;
        color: #0C356A;
    font-size: 14px;
    margin: 30px 0 15px 0;
    padding: 20px 0 20px 0;
    }
  }

  .pk-nav-btn1 {
    margin-left: 0px !important;
    padding: 5px 0 5px 0;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
