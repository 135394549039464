.action_Buttons {
    height: 5rem;
    width: 10rem;
    background-color: #0C356A !important;
    border: #0C356A;
    padding: 10px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
}

.action_Buttons:hover {
    background-color: #1253a8 !important;
}

.action_Button_Group {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 19px;
}

.site-bg-color {
    background-color: #0C356A !important;
    border: #0C356A;
}