* {
  -webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none; /* Standard syntax */
}

.pk-border-box {
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  border: 1px solid #3E7DC0;
  border-radius: 15px;
}

.underline {
  text-decoration: underlinee;
}

.pk-backround-white {
  background-color: #fff;
  padding: 5px 15px 5px 15px !important;
  color: #0C356A !important;
  border-radius: 15px;
  
}

a.pk-backround-white :hover {
  background-color: #fff !important;
  color: #0C356A !important;
}

.news-update-content p {
  margin-bottom: 0;
}