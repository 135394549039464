

.staff-img {
  width: 60%;
  height: auto;
  border-radius: 5%;
  margin: 0 auto;
}

.staff-card {
  border-radius: 4%;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow, 0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 15px;
}

.staff-card:hover {
  transform: scale(1.009);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  border: 1px solid #3E7DC0;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: #6c757d;
}
