    

    .serv-card{
        border-radius: 4px;
        background: #fff;
        box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
          transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
      padding: 14px 15px 18px 20px;
      cursor: pointer;
      font-family: Noto Serif Devanagari;
    }
    
    .serv-card:hover{
         transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
      border: 1px solid #3E7DC0;
    }

.card-img {
    height: 4rem;
    width: 4rem;
    padding: 10px;
}
