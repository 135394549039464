.create-post-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-top: 160px;
    padding-top:75px;
}

.create-post-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.submit-button {
    width: 100%;
}

.news-update-item {
    padding: 0;
}

.news-update-card {
    border: 1px solid #007bff;
    border-radius: 8px;
}

.news-update-header {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.edit-button {
    margin-right: 10px;
}
