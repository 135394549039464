.underLine {
    text-decoration: underline
}

  .text-white {
    color: white;
  }
  
  .gallery {
    padding: 20px;
  }
  
  .pk-img {
    cursor: pointer;
    max-width: 100%;
    height: -webkit-fill-available;
    margin-bottom: 20px;
    transition: transform 0.2s;
  }
  
  .pk-img:hover {
    transform: scale(1.05);
  }
  
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  